<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'
const store = useStore()
const user = store.getters['user/getUser'];

const format = (value) => {
  return new Intl.NumberFormat('en-US').format(value)
};
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">Ledger</h1>
    <RoomComponent>
      <table>
        <tbody>
          <tr v-for="item in user.ledgers" :key="item">
            <td class="w-full font-bold">{{ item.description }}</td>
            <td class="pe-1 font-extrabold">$</td>
            <td class="text-nowrap font-bold text-right">{{ format(item.value) }}</td>
          </tr>
        </tbody>
      </table>
    </RoomComponent>
  </div>
</template>