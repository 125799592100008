<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'
const store = useStore()

const status = store.getters['bid/getStatus'];
const company = store.getters['company/getCompany'];
const industry = store.getters['industry/getIndustry']
const user = store.getters['user/getUser'];
const getBid = store.getters['bid/getBid'];
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">My bids</h1>
    <RoomComponent :set="tmpCompanies = []">
      <div v-for="(bid, i) in user.bids" :key="bid" :set="tmpCompanies[i] = company(getBid(bid).company)">
        <table v-if="tmpCompanies[i] && status(getBid(bid).status) !== 'accepted'" class="w-full text-left mb-2">
          <tbody>
          <tr>
            <th class="text-xl" colspan="2"><span @click="selectCompany(tmpCompanies[i]);changeRoom('company');" class="cursor-pointer">{{ tmpCompanies[i].name }}</span></th>
            <th class="text-right text-sm">
              {{ industry(tmpCompanies[i].industry).name }}
            </th>
          </tr>
          <tr>
            <td class="text-sm">{{ tmpCompanies[i].sizeClassification }}</td>
          </tr>
          <tr>
            <th class="font-medium w-[50%]">Asking price</th>
            <td>$ {{ format(tmpCompanies[i].requestPrice) }}</td>
          </tr>
          <tr>
            <th class="font-medium w-[50%]">Amount</th>
            <td>$ {{ format(getBid(bid).amount) }}</td>
          </tr>
          <tr v-if="getBid(bid).counterOffer">
            <th class="font-medium">Counter offer</th>
            <td>$ {{ format(getBid(bid).counterOffer) }}</td>
          </tr>
          <tr v-if="getBid(bid).secondBidAmount">
            <th class="font-medium">Second bid amount</th>
            <td>$ {{ format(getBid(bid).secondBidAmount) }}</td>
          </tr>
          <tr v-if="getBid(bid).secondCounterOffer">
            <th class="font-medium">Second counter offer</th>
            <td>$ {{ format(getBid(bid).secondCounterOffer) }}</td>
          </tr>
          <tr v-if="getBid(bid).thirdBidAmount">
            <th class="font-medium">Third bid amount</th>
            <td>$ {{ format(getBid(bid).thirdBidAmount) }}</td>
          </tr>
          <tr>
            <th class="font-medium">Status</th>
            <td>{{ status(getBid(bid).status) }}</td>
          </tr>
          </tbody>
          <tbody>
          </tbody>
        </table>
      </div>
    </RoomComponent>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  methods: {
    format(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
    ...mapActions('room', [
      'changeRoom',
    ]),
    selectCompany(company) {
      this.$store.commit('investmentBank/setSelectedCompany', company)
    },
  }
}
</script>