<script setup>
import { ChevronLeftIcon }
  from '@heroicons/vue/24/outline'

import { useStore } from 'vuex'
const store = useStore()

</script>

<template>
  <div class="w-full md:w-1/3 md:min-w-[640px] m-auto h-full relative bg-gray-200">
    <div v-if="!loading" :class="`flex flex-col h-full bg-img ${room}`" :style="{ 'background-image': `url('https://api.test.cobrabeetle.nl/assets/${store.state.background}')` }">
      <TopBar/>
      <div class="content" :class="`grow`">
        <RoomAirport v-if="room === 'airport'"/>
        <RoomBid v-if="room === 'bid'"/>
        <RoomCity v-if="room === 'city'"/>
        <RoomInvestmentBank v-if="room === 'investment-bank'"/>
        <RoomProfile v-if="room === 'profile'"/>
        <RoomSettings v-if="room === 'settings'"/>
        <RoomPortfolio v-if="room === 'portfolio'"/>
        <RoomCompany v-if="room === 'company'"/>
        <RoomMyBids v-if="room === 'my-bids'"/>
        <RoomLedger v-if="room === 'ledger'"/>
      </div>
      <Menu/>
      <button v-if="previousRoom" class="block absolute top-10 left-3 rounded-full bg-gray-400 w-8 h-8 text-white" @click="gotoPreviousRoom()">
        <ChevronLeftIcon class="size-6 m-0.5"/>
      </button>
    </div>
    <div v-if="loading" class="absolute w-full h-full top-0 left-0 bg-slate-400 bg-opacity-75 flex justify-center flex-col text-center">
      <div>
        <div class="text-4xl font-bold mb-20">COBRA BEETLE</div>
        <div class="loader"></div>
        <div class="uppercase text-xl mt-4 font-medium">{{ status }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./components/MenuComponent.vue"
import RoomAirport from "./components/RoomAirport.vue"
import RoomBid from "./components/RoomBid.vue"
import RoomCity from "./components/RoomCity.vue"
import RoomInvestmentBank from "./components/RoomInvestmentBank.vue"
import RoomProfile from "./components/RoomProfile.vue"
import RoomSettings from "./components/RoomSettings.vue"
import RoomPortfolio from "./components/RoomPortfolio.vue"
import RoomCompany from "./components/RoomCompany.vue"
import RoomMyBids from "./components/RoomMyBids.vue"
import RoomLedger from "@/components/RoomLedger.vue"
import TopBar from "@/components/TopBarComponent.vue"
import {mapActions, mapGetters} from "vuex"

export default {
  name: 'App',
  components: {
    TopBar,
    RoomAirport,
    RoomBid,
    RoomCity,
    RoomInvestmentBank,
    RoomProfile,
    RoomSettings,
    RoomPortfolio,
    RoomCompany,
    RoomMyBids,
    RoomLedger,
    Menu,
  },
  data() {
    return {
      loading: true,
      status: 'Logging in...'
    }
  },
  computed: {
    ...mapGetters('room', {
      room: 'currentRoom',
      previousRoom: 'previousRoom',
    }),
  },
  methods: {
    ...mapActions('room', [
      'gotoPreviousRoom', 'gotoPreviousRoom'
    ]),
    ...mapActions('user', [
      'login', 'fetchUser'
    ]),
    ...mapActions('city', [
      'fetchCities'
    ]),
    ...mapActions('tier', [
      'fetchTiers'
    ]),
    ...mapActions('airport', [
      'fetchAirports'
    ]),
    ...mapActions('investmentBank', [
      'fetchInvestmentBanks'
    ]),
    ...mapActions('industry', [
      'fetchIndustries'
    ]),
    ...mapActions('company', [
      'fetchCompanies'
    ]),
  },
  async mounted() {
    await this.login().then(() => { this.status = 'Fetching current user...' })
    await this.fetchUser().then(() => { this.status = 'Loading cities...' })
    await this.fetchCities().then(() => { this.status = 'Loading airports...' })
    await this.fetchTiers().then(() => { this.status = 'Loading tiers...' })
    await this.fetchAirports().then(() => { this.status = 'Loading investment banks...' })
    await this.fetchInvestmentBanks().then(() => { this.status = 'Loading tiers...' })
    await this.fetchIndustries().then(() => { this.status = 'Loading companies...' })
    await this.fetchCompanies().then(() => { this.status = 'Loading bids...' })
    await this.$store.dispatch('bid/fetchBids')
    this.loading = false
  }
}
</script>

<style>
.bg-img {
  background-size: cover;
}
.content button {
  background: transparent url('./assets/buttons/button.png') no-repeat top left;
  width: 320px;
  height: 60px;
}

.loader {
  width: 100px;
  margin: auto;
  aspect-ratio: 1.154;
  clip-path: polygon(50% 0,100% 100%,0 100%);
  --c:no-repeat linear-gradient(#f03355 0 0, #f03355);
  background: var(--c),var(--c),var(--c),var(--c),var(--c);
  background-size: 100% calc(100%/5 + 1px);
  animation: l15 2s infinite;
}
@keyframes l15 {
  0%  {background-position: 0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
  20% {background-position: 0 calc(4*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
  40% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4),0 calc(-2*100%/4)}
  60% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(-2*100%/4),0 calc(-2*100%/4)}
  80% {background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(1*100%/4) ,0 calc(-2*100%/4)}
  100%{background-position: 0 calc(4*100%/4) ,0 calc(3*100%/4) ,0 calc(2*100%/4) ,0 calc(1*100%/4) ,0 calc(0*100%/4)}
}
</style>