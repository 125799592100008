<script setup>
import { useStore } from 'vuex'
import {ref} from "vue"
import { component as VueNumber } from '@coders-tm/vue-number-format'
import RoomComponent from './RoomComponent.vue'

import { Chart, registerables } from 'chart.js/auto';
import { Line } from 'vue-chartjs'
Chart.register(...registerables);

const store = useStore()

const user = store.getters['user/getUser']
const bid = store.getters['bid/getActiveBidForCompany'];
const status = store.getters['bid/getStatus'];
const company = store.getters['investmentBank/getSelectedCompany'];
const getWinningBid = store.getters['bid/getWinningBid']
let activeBid = ref(bid(company['@id']))
let bidAmount = company.requestPrice
let refreshInterval = null

const refreshBid = async () => {
  await store.dispatch('bid/fetchBid', activeBid.value.id).then((resp) => {
    if (activeBid.value.status !== resp.status) {
      activeBid.value = resp
      store.dispatch('bid/fetchBids')
      refreshInterval = null;
    }
  })
}
const submitBid = async (companyId, amount) => {
  await store.dispatch('investmentBank/submitBid', { companyId: companyId, amount: amount}).then((resp) => {
    activeBid.value = resp
    if (!refreshInterval) {
      refreshInterval = setInterval(function () {
        refreshBid();
      }, 20000)
    }
  });
  await store.dispatch('bid/fetchBids')
}

const submitSecondBid = async (bidId, companyId, amount) => {
  await store.dispatch('investmentBank/submitSecondBid', { bidId: bidId, amount: amount}).then(resp => {
    activeBid.value = resp
    if (!refreshInterval) {
      refreshInterval = setInterval(function () {
        refreshBid();
      }, 20000)
    }
  });
  await store.dispatch('bid/fetchBids')
}
const submitThirdBid = async (bidId, companyId, amount) => {
  await store.dispatch('investmentBank/submitThirdBid', { bidId: bidId, amount: amount}).then(resp => {
    activeBid.value = resp
    if (!refreshInterval) {
      refreshInterval = setInterval(function () {
        refreshBid();
      }, 20000)
    }
  });
  await store.dispatch('bid/fetchBids')
}

if (activeBid.value) {
  refreshInterval = setInterval(function () {
    refreshBid();
  }, 20000)
}

let salesData = [];
company.sales.slice(0,5).forEach((sales) => {
  salesData.push(sales.value/1000000)
})
let ebitdaData = [];
company.ebitda.slice(0,5).forEach((ebitda) => {
  ebitdaData.push(ebitda.value/1000000)
})
let chartData = ref({
  labels: ['-4', '-3', '-2', '-1', '0'],
  datasets: [
    {
      lineTension: 0.4,
      label: 'Ebitda',
      borderColor: '#e31d1d',
      backgroundColor: '#af1010',
      data: ebitdaData,
      yAxisID: 'y1',
    },
    {
      lineTension: 0.4,
      label: 'Sales',
      borderColor: '#0030ff',
      backgroundColor: '#384eb2',
      data: salesData,
      yAxisID: 'y',
      type: 'bar',
    },
  ]
})
let chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      beginAtZero: true,
      title: {
        display: true,
        text: 'Sales',
      },
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',

      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
      beginAtZero: true,
      title: {
        display: true,
        text: 'EBITDA',
      },
    },
    x: {
      display: true,
      title: {
        display: true,
        text: 'Period'
      }
    },
  },
})
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-4xl text-center mb-2 md:mb-4 font-medium">{{ company.name }}</h1>
    <RoomComponent>
      <div class="flex flex-col h-full">
        <div class="flex-grow">
          <table class="w-full text-left text-sm font-medium mb-2">
            <tbody>
            <tr>
              <td colspan="2">{{ company.description }}</td>
            </tr>
            <tr>
              <td class="w-[33%]">Industry</td>
              <td>{{ industry(company.industry).name }}</td>
            </tr>
            <tr v-if="company.owner !== user['@id']">
              <td>Asking price</td>
              <td>$ {{ format(company.requestPrice) }}</td>
            </tr>
            <tr v-if="company.owner === user['@id']">
              <td>Purchased for</td>
              <td>
                $
                <span v-if="getWinningBid(company['@id']).thirdBidAmount">{{ format(getWinningBid(company['@id']).thirdBidAmount) }}</span>
                <span v-else-if="getWinningBid(company['@id']).secondBidAmount">{{ format(getWinningBid(company['@id']).secondBidAmount) }}</span>
                <span v-else>{{ format(getWinningBid(company['@id']).amount) }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="bg-white/75 mt-3 mb-3" style="height: 250px;">
          <Line
              :options="chartOptions"
              :data="chartData"
          />
        </div>

        <div v-if="company.owner !== user['@id']">
          <div v-if="bidsForCompany(company['@id']).length">
            <table class="w-full mb-2 text-left">
              <thead>
              <tr>
                <th>Amount</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="bid in bidsForCompany(company['@id'])" :key="bid">
                <template v-if="!activeBid || activeBid.id !== bid.id">
                  <tr>
                    <td class="font-medium">$ {{ format(getCurrentAmount(bid)) }}</td>
                    <td class="font-medium">
                      {{ status(bid.status) }}<br>
                      <em v-if="bid.status === 3">
                      $ {{ format(bid.counterOffer) }}
                      </em>
                      <em v-if="bid.status === 5">
                      $ {{ format(bid.secondCounterOffer) }}
                      </em>
                    </td>
                  </tr>
                </template>
              </template>
              </tbody>
            </table>
          </div>

          <div class="text-xl font-medium mb-2" v-if="activeBid">
            Current bid: $
            <span v-if="activeBid.status < 4">
            {{ format(activeBid.amount) }}
            </span>
            <span v-else-if="activeBid.status < 6">
            {{ format(activeBid.secondBidAmount) }}
            </span>
            <span v-else-if="activeBid.status < 8">
            {{ format(activeBid.thirdBidAmount) }}
            </span>
            <br>
            Status: {{ status(activeBid.status) }}<br>
            <span v-if="activeBid.status === 3">
              Amount: $ {{ format(activeBid.counterOffer) }}
            </span>
            <span v-if="activeBid.status === 5">
              Amount: $ {{ format(activeBid.secondCounterOffer) }}
            </span>
          </div>

          <div v-if="!activeBid || activeBid.status === 3 || activeBid.status === 5" class="text-center">
            <div>
            <VueNumber v-if="!activeBid || activeBid.status === 3 || activeBid.status === 5" type="text" class="w-full h-8 p-2 outline-0 text-right" v-model="bidAmount" v-bind="{ prefix: '$ ' }"/>
            </div>
            <button v-if="!activeBid" class="my-2" @click="submitBid(company.id, bidAmount)">
              <span>Submit opening bid</span>
            </button>
            <button v-else-if="activeBid.status === 3" class="my-2" @click="submitSecondBid(activeBid.id, company.id, bidAmount)">
              <span>Submit second bid</span>
            </button>
            <button v-else-if="activeBid.status === 5" class="my-2" @click="submitThirdBid(activeBid.id, company.id, bidAmount)">
              <span>Submit third bid</span>
            </button>
          </div>
        </div>

        <div v-if="company.owner === user['@id']" class="text-center">
          <button>
            <span>Put company up for sale</span>
          </button>
        </div>
      </div>
    </RoomComponent>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters('industry', {
      industry: 'getIndustry',
    }),
    ...mapGetters('bid', {
      bidsForCompany: 'getBidsForCompany',
      getCurrentAmount: 'getCurrentAmount',
    }),
  },
  methods: {
    ...mapActions('room', [
      'changeRoom',
    ]),
    format(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
  },
}
</script>