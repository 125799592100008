<script setup>
import { useStore } from 'vuex'
import RoomComponent from "@/components/RoomComponent.vue";
const store = useStore()

store.state.investmentBank.currentInvestmentBankCompanies = []
const current = store.getters['investmentBank/getCurrentInvestmentBank']
const user = store.getters['user/getUser']
const investmentBank = store.getters['investmentBank/getInvestmentBank'](current)
const city = store.getters['city/getCity']
store.dispatch('investmentBank/fetchCompaniesForInvestmentBank', investmentBank.id)
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-xl text-center uppercase">{{ city(user.currentCity).name }}</h1>
    <h1 class="text-4xl mb-2 md:mb-4 text-center font-medium">
      {{ investmentBank.name }}
      <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full relative top-[-14px]">
        {{ tier(user.tier).level }}
      </span>
    </h1>
    <RoomComponent>
      <p class="text-center">Welcome at {{ investmentBank.name }}</p>
      <p>We have the following opportunities for you:</p>
      <div v-for="company in getCurrentInvestmentBankCompanies" :key="company" class="text-center">
        <button class="my-2" @click="changeRoom('company');selectCompany(company)">{{ company.name }} ($ {{ format(company.requestPrice) }})</button>
      </div>
      <p>We have a client that may be interested in one of your portfolio companies:</p>
      <div class="text-center">
        <button class="my-2">Bellcall Networks ($ 61.000.000)</button>
      </div>
    </RoomComponent>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters('tier', {
      tier: 'getTier',
    }),
    ...mapGetters('investmentBank', [
      'getCurrentInvestmentBankCompanies',
    ]),
  },
  methods: {
    ...mapActions('room', [
      'changeRoom',
    ]),
    format(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
    selectCompany(company) {
      this.$store.commit('investmentBank/setSelectedCompany', company)
    },
  },
}
</script>
