const state = () => ({
  companies: [],
})

// getters
const getters = {
  getCompany: (state) => (uri) => state.companies.find(company => company['@id'] === uri),
}

// actions
const actions = {
  async fetchCompanies({ commit, rootState }) {
    try {
      const data = await fetch(
        process.env.VUE_APP_API_URL + "/companies", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + rootState.user.token,
          },
        },
      )
      const result = await data.json()
      commit("setCompanies", result['hydra:member'])
    } catch (error) {
      console.log(error)
    }
  },
}

// mutations
const mutations = {
  setCompanies(state, companies) {
    state.companies = companies
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}