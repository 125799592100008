<script setup>
import { useStore } from 'vuex'
import RoomComponent from './RoomComponent.vue'
const store = useStore()

const user =  store.state.user.user
const company = store.getters['company/getCompany']
const getWinningBid = store.getters['bid/getWinningBid']
const industry = store.getters['industry/getIndustry']
const city = store.getters['city/getCity']
let myCompanies = user.companies
</script>

<template>
  <div class="h-full py-6 md:py-10 px-5 flex flex-col">
    <h1 class="text-5xl text-center mb-2 md:mb-4 font-medium">Portfolio</h1>
    <RoomComponent :set="tmpCompanies = []">
      <table v-for="(uri, i) in myCompanies" :key="uri" class="w-full text-left mb-2" :set="tmpCompanies[i] = company(uri)">
        <tbody>
        <tr>
          <th class="text-xl" colspan="2">
            <span class="cursor-pointer" @click="changeRoom('company');selectCompany(tmpCompanies[i])">{{ tmpCompanies[i].name }}</span>
          </th>
          <th class="text-right text-sm">
            {{ industry(tmpCompanies[i].industry).name }}
            <span class="text-sm font-medium block">{{ tmpCompanies[i].sizeClassification }}</span>
          </th>
        </tr>
        <tr>
          <th class="text-sm">
            {{ city(tmpCompanies[i].city).name }}
          </th>
        </tr>
        <tr>
          <th class="font-medium w-[50%]">Sales</th>
          <td>$ {{ format(tmpCompanies[i].currentSales) }}</td>
        </tr>
        <tr>
          <th class="font-medium">EBITDA</th>
          <td>$ {{ format(tmpCompanies[i].currentEbitda) }}</td>
        </tr>
        <tr v-if="getWinningBid(tmpCompanies[i]['@id'])">
          <th class="font-medium">Winning bid</th>
          <td>
            $
            <span v-if="getWinningBid(tmpCompanies[i]['@id']).thirdBidAmount">{{ format(getWinningBid(tmpCompanies[i]['@id']).thirdBidAmount) }}</span>
            <span v-else-if="getWinningBid(tmpCompanies[i]['@id']).secondBidAmount">{{ format(getWinningBid(tmpCompanies[i]['@id']).secondBidAmount) }}</span>
            <span v-else>{{ format(getWinningBid(tmpCompanies[i]['@id']).amount) }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </RoomComponent>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions('room', [
      'changeRoom',
    ]),
    format(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
    selectCompany(company) {
      this.$store.commit('investmentBank/setSelectedCompany', company)
    },
  },
}
</script>